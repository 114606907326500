import React from "react";
import "./SchemeHero.css"; // Import CSS for styling

const FutureHero= () => {
  return (
    <div className="scheme-hero-container">
      <img
        src="assets/schemes/future.png"
        alt="Digi Hero"
        className="scheme-hero-image"
      />
    </div>
  );
};

export default FutureHero;
