import React from "react";
import "./SchemeHero.css"; // Import CSS for styling

const SchemeHero = () => {
  return (
    <div className="scheme-hero-container">
      <img
        src="assets/schemes/schemes.png"
        alt="Scheme Hero"
        className="scheme-hero-image"
      />
    </div>
  );
};

export default SchemeHero;
