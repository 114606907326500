import React from "react";
import "./SchemeHero.css"; // Import CSS for styling

const FlexiImage = () => {
  return (
    <div className="scheme-hero-container">
      <img
        src="assets/schemes/flexi.png"
        alt="Digi Hero"
        className="scheme-hero-image"
      />
    </div>
  );
};

export default FlexiImage;
